import axios from "axios";
import { notification } from "antd";

const axiosConfiguration = () =>
  axios.create({
    timeout: 30000,
    withCredentials: true,
  });

const axiosInstance = axiosConfiguration();

axiosInstance.interceptors.request.use(async (config) => {
  config.headers["Accept"] = "application/json";
  return config;
});

axiosInstance.interceptors.response.use(
  (response) => {
    if (response?.data) {
      if (response?.data?.success) {
        return response;
      } else {
        notification.warning({
          message: "ERROR!!!",
          description: response.data.message || "Something went wrong!",
        });
      }
    }
  },
  (error) => {
    const status = error?.response?.status || 500;
    const errorMessage =
      error?.response?.data?.errors && status === 422
        ? Object.values(error.response.data.errors).join(" ")
        : error?.response?.data?.message || "Something went wrong!";

    if (status === 401) {
      localStorage.removeItem("auth.id");
      window.location = "/";
    } else {
      notification.warning({
        message: "ERROR!!!",
        description: errorMessage,
      });
    }
  }
);

export default axiosInstance;
