import { NavLink, useNavigate } from "react-router-dom";
import style from "../../styles/layout/header.module.scss";
import logo from "../../assets/logo/sms.png";
import { useSelector } from "react-redux";
import { memo } from "react";

const Header = () => {
  const navigate = useNavigate();
  const { authid } = useSelector((state) => state.auth);

  return (
    <div className={style.headercontainer}>
      <div className={style.logocontainer}>
        <img
          src={logo}
          className={style.logo}
          alt="stock market sentiments"
          onClick={() => navigate("/")}
        />
        <p className={style.title} onClick={() => navigate("/")}>
          Stock Market Sentiments
        </p>
      </div>
      {authid && (
        <div className={style.navcontainer}>
          <NavLink
            to="/chart"
            className={({ isActive }) =>
              isActive ? style.activenav : style.nav
            }
          >
            Chart
          </NavLink>
          <NavLink
            to="/payment"
            className={({ isActive }) =>
              isActive ? style.activenav : style.nav
            }
          >
            Subscribe
          </NavLink>
          <NavLink
            to="/intraday-strategy"
            className={({ isActive }) =>
              isActive ? style.activenav : style.nav
            }
          >
            Strategy
          </NavLink>
        </div>
      )}
    </div>
  );
};

export default memo(Header);
