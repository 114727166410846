import { Navigate, Route, Routes } from "react-router-dom";
import React, { Suspense } from "react";
import { useSelector } from "react-redux";
import { Spin } from "antd";
import Header from "./components/layout/Header";
import style from "./styles/layout/app.module.scss";

const AuthPage = React.lazy(() => import("./pages/AuthPage"));
const ChartPage = React.lazy(() => import("./pages/ChartPage"));
const PaymentPage = React.lazy(() => import("./pages/PaymentPage"));
const StrategyPage = React.lazy(() => import("./pages/StrategyPage"));
const RedirectPage = React.lazy(() => import("./pages/RedirectPage"));
const IntradayStrategyPage = React.lazy(() =>
  import("./pages/IntradayStrategyPage")
);

const App = () => {
  const { authid } = useSelector((state) => state.auth);

  return authid ? (
    <Suspense fallback={<Spin size="large" className={style.spinner} />}>
      <Header />
      <Routes>
        <Route path="/chart" element={<ChartPage />} />
        <Route path="/payment" element={<PaymentPage />} />
        <Route path="/strategy" element={<StrategyPage />} />
        <Route path="/intraday-strategy" element={<IntradayStrategyPage />} />
        <Route path="*" element={<Navigate to={"/chart"} />} />
      </Routes>
    </Suspense>
  ) : (
    <Suspense fallback={<Spin size="large" className={style.spinner} />}>
      <Routes>
        <Route path="/auth" element={<AuthPage />} />
        <Route
          path="/strategy"
          element={
            <>
              <Header />
              <StrategyPage />
            </>
          }
        />
        <Route path="*" element={<RedirectPage />} />
      </Routes>
    </Suspense>
  );
};

export default App;
