import axiosInstance from "../utils/configureAxios";
import { AUTH_API_HOST } from "../utils/constants";

export const loginApi = async (data) => {
  const response = await axiosInstance.post(AUTH_API_HOST + "login", data);
  return response?.data || null;
};

export const registerApi = async (data) => {
  const response = await axiosInstance.post(AUTH_API_HOST + "signup", data);
  return response?.data || null;
};

export const verifyApi = async (data) => {
  const response = await axiosInstance.post(AUTH_API_HOST + "verify", data);
  return response?.data || null;
};
