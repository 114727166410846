import { all, takeEvery, put, call } from "redux-saga/effects";
import { loginApi, registerApi, verifyApi } from "../../services/auth";
import authActions from "./actions";
import appActions from "../app/actions";

export function* WATCH_LOGIN(action) {
  const response = yield call(loginApi, action.payload);
  try {
    if (response) {
      if (response.success) {
        yield put({
          type: appActions.AUTH_STATE,
          payload: "VERIFY_OTP",
        });
      }
    }
  } catch {
    console.log(response.data);
  }
}

export function* WATCH_REGISTER(action) {
  const response = yield call(registerApi, action.payload);
  try {
    if (response) {
      if (response.success) {
        yield put({
          type: appActions.AUTH_STATE,
          payload: "VERIFY_OTP",
        });
      }
    }
  } catch {
    console.log(response.data);
  }
}

export function* WATCH_VERIFY(action) {
  const response = yield call(verifyApi, action.payload);
  try {
    if (response) {
      if (response.success) {
        yield put({
          type: authActions.AUTH,
          payload: response.data?._id,
        });
        yield put({
          type: appActions.AUTH_STATE,
          payload: "SEND_OTP",
        });
      }
    }
  } catch {
    console.log(response.data);
  }
}

export default function* rootSaga() {
  yield all([takeEvery("LOGIN", WATCH_LOGIN)]);
  yield all([takeEvery("REGISTER", WATCH_REGISTER)]);
  yield all([takeEvery("VERIFY", WATCH_VERIFY)]);
}
