import authActions from "./actions";

const initialState = {
  authid: "",
};

const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case authActions.AUTH: {
      localStorage.setItem("auth.id", action.payload);
      return { ...state, authid: action.payload };
    }
    default: {
      const authid = localStorage.getItem("auth.id");
      return { ...state, authid: authid || "" };
    }
  }
};

export default authReducer;
