import { Tuple, configureStore } from "@reduxjs/toolkit";
import { logger } from "redux-logger";
import createSagaMiddleware from "redux-saga";
import sagas from "./sagas";
import authReducer from "./auth/reducers";
import appReducer from "./app/reducers";

const reducer = {
  auth: authReducer,
  app: appReducer,
};

const sagaMiddleware = createSagaMiddleware();
const store = configureStore({
  reducer,
  middleware: () => new Tuple(sagaMiddleware),
});
sagaMiddleware.run(sagas);

export default store;
