import appActions from "./actions";

const initialState = {
  authState: "SEND_OTP",
  symbol: "NIFTY",
};

const appReducer = (state = initialState, action) => {
  switch (action.type) {
    case appActions.AUTH_STATE: {
      return { ...state, authState: action.payload };
    }
    case appActions.SELECTED_SYMBOL: {
      localStorage.setItem("symbol", action.payload);
      return { ...state, symbol: action.payload };
    }
    default: {
      const symbol = localStorage.getItem("symbol");
      return { ...state, authState: "SEND_OTP", symbol: symbol || "NIFTY" };
    }
  }
};

export default appReducer;
